import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({ 
  root: {
    display: 'flex',
    color: 'white',
    overflow: 'hidden',
    fontFamily: 'Century-Gothic',
    textAlign: 'center',
    backgroundImage: "linear-gradient(rgba(69, 69, 69, 0.45), rgba(69, 69, 69, 0.45)), url('/images/homepage/backgroundHowWorks.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  
  
  containerItem: {
    //backgroundColor: 'green',
    [theme.breakpoints.down('sm')]: {
      marginTop: 25,
      marginBottom: 25,
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2, 3),
    //backgroundColor: 'red',
  },
  title: {
    marginBottom: theme.spacing(14),
    fontFamily: 'Century-Gothic',
  },
  number: {
    fontFamily: 'Century-Gothic',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  image: {
    height: 120,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  text:{
    fontFamily: 'Century-Gothic',
    [theme.breakpoints.down('sm')]: {
      //fontSize: 15,
    }
    //fontSize: 'calc(7px + 2vmin) !important',
  },
  button: {
    marginTop: theme.spacing(8),
    fontFamily: 'Century-Gothic',
    fontSize: 'x-large',
    backgroundColor: '#33658A',
    borderRadius: '6px',
    paddingBlockStart: '10px',
    paddingBlockEnd: '10px',
    paddingInlineStart: '25px',
    paddingInlineEnd: '25px',
    fontWeight: 'bold',
    color: 'white',
    '&:hover': {
      backgroundColor: '#33658A',
    },
  },
  textlink:{
    fontWeight: 'bold',
    color: 'white',
    textDecoration: 'none',
    fontFamily: 'Century-Gothic',
    '&:hover': {
      color: 'white',
      textDecoration: 'none',
    },
  }, 
}));
export default useStyles;