import Image from "next/image";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "../typography"; 
import useStyles from "./style.js";

export default function ProductFeatures() {
  const classes = useStyles();

  return (
    <Grid className={classes.root}> 
      <Container className={classes.container}>
        <Typography
          variant="h4"
          marked="center"
          className={classes.principal}
          component="h2"
        >Nuestros valores</Typography>

        <Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <Typography variant="h5" className={classes.title}>
                  COMPROMISO
                </Typography>

                <Grid className={classes.image}>
                <Image
                  src={"/images/icons/DiamanteFeatures.svg"} 
                  alt="Diamante"
                  width={64} 
                  height={64} 
                  draggable='false'
                  layout = "fixed"
                  title={'Compromiso'}/>
                </Grid>
                <Typography variant="h6" className={classes.content}>
                  CIDU tiene conciencia de la importancia que tiene el cumplir
                  con la palabra dada, con el desarrollo de su trabajo dentro
                  del plazo estipulado. El trabajo es asumido con
                  profesionalidad, responsabilidad y lealtad, poniendo el mayor
                  esfuerzo para lograr un producto con un alto estándar de
                  calidad que satisfaga y supere las expectativas de los
                  clientes.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <Typography variant="h5" className={classes.title}>
                  IGUALDAD
                </Typography>
                <Grid className={classes.image}>
                <Image 
                  src={"/images/icons/PlumaFeature.svg"}   
                  alt="Pluma"
                  width={64} 
                  height={64} 
                  draggable='false'
                  layout = "fixed"
                  title={'Igualdad'}/>
                </Grid>
                <Typography variant="h6" className={classes.content}>
                  CIDU le brinda a sus clientes y asociados un trato idéntico y
                  sin discriminación, sin que medie ningún tipo de reparo por la
                  raza, genero e identidad de genero, clase social u otra
                  circunstancia plausible de diferencia.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <Typography variant="h5" className={classes.title}>
                  DISCIPLINA
                </Typography>
                <Grid className={classes.image}>
                <Image
                  src={"/images/icons/BombilloFeatures.svg"} 
                  alt="Bombillo"
                  width={64} 
                  height={64} 
                  draggable='false'
                  layout = "fixed"
                  title={'Disciplina'}/>
                </Grid>
                <Typography variant="h6" className={classes.content}>
                  CIDU actúa de manera ordenada y perseverante en cada uno de
                  sus procesos. Mantiene una estricta planificación, un orden y
                  lineamientos claros para lograr los objetivos fijados,
                  evitando así las improvisaciones en sus procedimientos.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <Typography variant="h5" className={classes.title}>
                  UNIVERSALIDAD
                </Typography> 
                <Grid className={classes.image}>
                <Image
                  src={"/images/icons/RayoFeature.svg"} 
                  alt="Rayo"
                  width={64} 
                  height={64} 
                  draggable='false' 
                  layout = "fixed"
                  title={'Universalidad'}/>
                </Grid>
                <Typography variant="h6" className={classes.content}>
                  CIDU se mantiene a la vanguardia en conocimientos técnicos
                  locales como internacionales, perfeccionando así los servicios
                  ofrecidos con base en criterios universales de valuación y
                  urbanismo.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
