import React from "react";
import Head from "next/head";
import Link from 'next/link';
import AppBar from "../components/appBar";
import Footer from "../components/footer";
import Grid from "@material-ui/core/Grid";
import ProductHero from "../components/productHero";
import ProductValues from "../components/productFeatures";
import ProductProjects from "../components/productProjects";
import ProductHowItWorks from "../components/productHowWorks";
import WidgetWa from "../components/widgetWa";
//import Maintenance from "../components/maintenance"; 

//COOKIES
import CookieConsent from "react-cookie-consent";

export default function Home() {
  return (
    <> 
      <Head>
        <title>CIDU</title>
        <link rel="icon" href="/favicon.ico" /> 
        <meta charSet="utf-8" />
        <meta itemProp="description" content="Consultoría de Ingeniería & Desarrollo Urbano S.A.S - Información confiable para decisiones efectivas."/>
        <link rel="canonical" href="https://cidu.com.co/" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <Grid>
        {/* <Maintenance /> */}
        <AppBar />
        <ProductHero/>
        <ProductValues/>
        <ProductProjects/>
        <ProductHowItWorks/>
        <Footer />
      </Grid>
      <WidgetWa
        link={
          "https://wa.me/573014606980/?text=Hola%20CIDU,%20quiero%20contactarme%20con%20Ustedes!"
        }
      />

      <CookieConsent
        location="bottom"
        buttonText="Aceptar y Cerrar"
        cookieName="CiduCookieConsent"
        style={{
          background: "#28282abb",
          paddingBottom: 10,
          paddingTop: 10,
          flexDirection: "column",
          alignItems: "center",
        }}
        buttonStyle={{
          background: "#fff",
          color: "#33658a",
          fontSize: "13px",
          fontFamily: "Century-Gothic",
          paddingBlockStart: "10px",
          paddingBlockEnd: "10px",
          paddingInlineStart: "25px",
          paddingInlineEnd: "25px",
          fontWeight: "bold",
        }} 
        expires={150}
        overlay
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
        contentStyle={{
          flex: 1,
          textAlign: "center",
          fontFamily: "Century-Gothic",
        }}
        cookieSecurity
        sameSite={"lax"}
      >
        En CIDU SAS utilizamos “cookies” propias con el fin de mejorar nuestro
        servicio y presentar contenidos relacionados con las preferencias de los
        usuarios mediante el análisis de sus hábitos de navegación. Si continúas
        navegando, consideramos que aceptas su uso. &nbsp; &nbsp;
        <span style={{textDecoration: 'underline'}} ><Link href="/policy" >
           Leer más. 
        </Link></span>
      </CookieConsent>
    </>
  );
}
