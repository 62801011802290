import React, { useState } from 'react';
import Image from 'next/image';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import DialogCidu from '../dialogCidu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './style.js';

export default function ProductProjects() {

    const classes = useStyles();
    const [stateOpen, setOpen] = useState({
        proyectoA: false,
        proyectoB: false,
        proyectoC: false,
        proyectoD: false, 
        proyectoE: false,
        proyectoF: false,
        proyectoG: false,
        proyectoH: false, 
        proyectoI: false
    });
    
    const toggleDrawer = (dialog, open) => () => { 
        setOpen({ ...stateOpen, [dialog]: open });
      };

    const theme = useTheme();
    const fullScreenQuery = useMediaQuery(theme.breakpoints.down('sm'));
    //public\images\homepage\projects\proyecto-REVISIONAVALUOECCI.jpg
    const images = [
        {
            url: "/images/homepage/projects/proyecto-AVALUOCASADECONSERVACION.jpg",
            title: 'CASA DE CONSERVACIÓN',
            width: '45%',
            content: 'Avalúo comercial del bien de interés cultural con ficha 008101-003-009 ubicado en el barrio Sagrado Corazón (Localidad de Santa Fe, Bogotá D.C.), en el cual utilizamos la técnica de comparación de mercado teniendo en cuenta la categoría de Conservación Tipológica y sus valores patrimoniales: Antigüedad, Autenticidad, Constitución del bien, Contexto urbano, entre otros.',
        },
        {
            url: "/images/homepage/projects/proyecto-AVALUOCCMERCURIO.jpg",
            title: 'HOME SENTRY',
            width: '55%',
            content: 'En el desarrollo del avalúo comercial y para Normas NIIF del predio de Gran Superficie HomeSentry de la AC 116 (Localidad de Usaquén, Bogotá D.C.), y ante la ausencia de ofertas directas comparables, utilizamos la técnica residual inversa.',
        },
        {
            url: "/images/homepage/projects/proyecto-AVALUOHOMESENTRY.jpg",
            title: 'CC MERCURIO',
            width: '40%',
            content: 'Avalúo comercial de Propiedad, Planta y Equipo para Normas NIIF del predio de Gran Superficie Centro Comercial Mercurio (Municipio de Soacha), para el cual realizamos verificación de inventario y comparación de mercado para cada activo, teniendo en cuenta obsolescencia física, tecnológica y depreciación por Ross-Heidecke para los que aplique.',
        },
        {
            url: "/images/homepage/projects/proyecto-PLUSVALIALAMARLENE.jpg",
            title: 'PLUSVALIA LA MARLENE',
            width: '40%',
            content: 'En el cálculo y liquidación del efecto plusvalía para el Plan Parcial La Marlene (Localidad de Bosa, Bogotá D.C.), proyecto desarrollado por CUSEZAR, utilizamos la técnica residual antes y después de la configuración del hecho generador, para determinar el incremento del valor del suelo.',
        },
        {
            url: "/images/homepage/projects/proyecto-REVISIONAVALUOECCI.jpg",
            title: 'REVISION AVALUO ECCI',
            width: '20%',
            content: 'Elaboración del avalúo comercial como soporte de la reclamación del avalúo catastral del Campus Calle 170 de la universidad ECCI (Localidad de Suba, Bogotá D.C.). Ante la ausencia de ofertas directas comparables, utilizamos la técnica residual con base en la edificabilidad permitida de acuerdo con el Plan Maestro de Equipamientos Educativos.',
        }, 
    ];





    return (
        <Grid className={classes.root}>
            <Container className={classes.container} component="section">
                <Typography variant="h4" marked="center" align="center" component="h2" className={classes.tittle}>
                    Algunos de nuestros proyectos
                </Typography>
                <Grid className={classes.images}>

                    {images.map((image) => (
                        <ButtonBase
                            key={image.title}
                            className={classes.imageWrapper}
                            style={{ width: image.width, }} 
                            disabled={stateOpen[image.title]  &&  true}
                            onClick={toggleDrawer(image.title, true)}>
                            <Grid
                                className={classes.imageSrc}
                                style={{
                                    backgroundImage: `url(${image.url})`,
                                }}
                            />

                            <Grid className={classes.imageBackdrop} />

                            <Grid className={classes.imageButton}>
                                <Typography
                                    component="h3"
                                    variant="h6"
                                    color="inherit"
                                    className={classes.imageTitle}
                                >
                                    {image.title}
                                    <Grid className={classes.imageMarked} />
                                </Typography>
                            </Grid>

                            <DialogCidu
                                show={stateOpen[image.title] ? true : false} 
                                close={toggleDrawer(image.title, false)}
                                header={image.title}
                                othercontent={<Grid item
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="stretch" >
                                                    <Grid item> 
                                                    <Image alt="avaluo" src={image.url} width={450} height={350}  title={image.title}/> 
                                                    </Grid> 
                                            </Grid>}  
                                content={image.content}
                                fullScreen={fullScreenQuery}
                                actionBtn={toggleDrawer(image.title, false)}/>

                        </ButtonBase>
                    ))}
                </Grid>
            </Container> 
        </Grid>
    );
} 