import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Hidden from '@material-ui/core/Hidden';

function WidgetWa(props) { 
    return (
        <Hidden xsDown={props.hidden}>
            <Grid style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 50}}>
                <IconButton
                    href={props.link}
                    target="_blank"
                    rel="noopener noreferrer" 
                    style={{ background: '#33658a', zIndex: 5, /*boxShadow: '0px 0px 10px #e4e4e4'*/ }}
                > <WhatsAppIcon style={{ fontSize: 35, color: '#ffffff' }} />
                </IconButton>
            </Grid>
        </Hidden>
    );
}
export default WidgetWa;