import Router from 'next/router';
import ProductHeroLayout from "./ProductHeroLayout";
import Typography from "../typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid"; 
//STYLES
import useStyles from "./style.js";

export default function ProductHero() { 
  
  const classes = useStyles();  

  const gotoLink = (e) => Router.push(e); //window.open(e, "_blank", 'noopener,noreferrer');

  return (
    <ProductHeroLayout backgroundClassName={classes.background}> 
      <Grid align="center">
        <Typography
          color="inherit"
          align="center"
          variant="h3"
          marked="center"
          className={classes.title}
        >Consultoría de Ingeniería <br /> & Desarrollo Urbano S.A.S</Typography>
        <Typography
          color="inherit"
          align="center"
          variant="h5"
          className={classes.h5}
        >Información confiable para decisiones efectivas</Typography>

        <Button
          className={classes.button}
          size="large" 
          onClick={() => gotoLink("/planes")}
        >{"Comenzar"}</Button>

        <Typography variant="body1" color="inherit" className={classes.more}>
          Hacer de CIDU su colaborador de preferencia, es obtener un servicio
          alta calidad y de confianza para siempre.
        </Typography>
      </Grid>
    </ProductHeroLayout>
  );
}
