import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    textAlign: "center",
    color: "white",
    fontFamily: "Century-Gothic",
    backgroundImage: "linear-gradient(rgba(51, 101, 138, 0.65), rgba(51, 101, 138, 0.65)), url('/images/homepage/backgroundFeatures.jpg') !important",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 2),
  },
  principal: {
    marginBottom: theme.spacing(10),
    fontFamily: "Century-Gothic",
  },
  image: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginBottom: theme.spacing(6),
  },
  title: {
    fontFamily: "Century-Gothic",
    marginBottom: theme.spacing(6),
    fontWeight: "bold",
  },
  content: {
    fontFamily: "Century-Gothic",
  },
}));
export default useStyles;
