import { makeStyles } from '@material-ui/core/styles';
//mport PrincipalBackground from '../../assets/img/homepage/backgroundHome.jpg'

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: 'url("/images/homepage/backgroundHome.jpg")',
        backgroundPosition: 'center',
        fontFamily: 'Century-Gothic',
        fontSize: 'calc(10px + 2vmin) !important',
        fontWeight: 'bold',
    },
    button: {
        minWidth: 200,
        fontFamily: 'Century-Gothic',
        backgroundColor: '#33658A',
        borderRadius: '6px',
        paddingBlockStart: '15px',
        paddingBlockEnd: '15px',
        paddingInlineStart: '25px',
        paddingInlineEnd: '25px',
        fontWeight: 'bold',
        color: 'white',
        '&:hover': {
            backgroundColor: '#33658A',
        },
    },
    title:{
        fontFamily: 'Century-Gothic',
        fontWeight: 'bold',
    },
    h5: {
        fontFamily: 'Century-Gothic',
        fontWeight: 'bold',
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(10),
        },
    },
    more: {
        fontFamily: 'Century-Gothic',
        marginTop: theme.spacing(4),
    },
}));
export default useStyles;



