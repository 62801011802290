import Router from "next/router";
import Image from 'next/image';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '../typography';
import useStyles from './style.js';

export default function ProductHowItWorks() {

  const classes = useStyles();

  function goto(e) {
    let value = e.currentTarget.value 
    Router.push(value)
  }

  return (
    <Grid className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h4" marked="center" className={classes.title} component="h2">
          ¿Cómo hacer de CIDU su colaborador de preferencia?
        </Typography>

        <Grid item container  
          justify="center"
          alignItems="center" >

          <Grid item xs={12} md={4}  className={classes.containerItem}>
            <Grid className={classes.item}>

              <Typography variant="h4" align="center" className={classes.number}>
                1. Contáctanos
                  </Typography> 
              <Grid className={classes.image} >
                <Image
                  src={"/images/icons/findConctact.svg"}
                  alt="contacts"
                  layout="fixed"
                  width={120} 
                  height={120} 
                  draggable= 'false'
                  title={'Contáctanos'} />
                </Grid>
              <Typography variant="h6" align="center" className={classes.text}>
                Indicanos por medio de nuestros canales de comunicación, cuál es la necesidad de servicio que presentas.
                  Link de contacto: <br />
                <a href="/contacto"
                  //underline="none"
                  className={classes.textlink}>https://www.cidu.com.co/contacto </a>
                <br />
                  Correo electrónico:
                  direccion@cidu.com.co
                  <br />Celular:
                  301 460 69 80

                  </Typography>
            </Grid>
          </Grid>

          <Grid item xs={11} md={4} className={classes.containerItem}>
            <Grid className={classes.item}>
              <Typography variant="h4" align="center" className={classes.number}>
                2. Analiza nuestras propuestas de servicio
                  </Typography>
              <Grid className={classes.image} >
                <Image 
                  src={"/images/icons/termConctact.svg"}
                  alt="services"
                  layout="fixed"
                  width={120} 
                  height={120} 
                  draggable= 'false'
                  title={'Servicio'} />
                </Grid>
              <Typography variant="h6" align="center" className={classes.text}>
                De acuerdo con las necesidades del cliente, se plantean las
                diferentes propuestas de servicio, atendiendo cada uno de los
                requerimientos planteados. </Typography>
            </Grid>
          </Grid>


          <Grid item xs={11} md={4} className={classes.containerItem}>
            <Grid className={classes.item}>
              <Typography variant="h4" align="center" className={classes.number}>
                3. Firma un acuerdo de servicio
                  </Typography>
              <Grid className={classes.image} >
                <Image
                  src={"/images/icons/confirmConctact.svg"}
                  alt="confirmations"
                  layout="fixed"
                  width={120} 
                  height={120} 
                  draggable= 'false'
                  title={'Acuerdo'} />
                </Grid>
              <Typography variant="h6" align="center" className={classes.text}>
                Una vez definido el alcance del servicio, se procede a la
                la firma de la orden de servicio o de un convenio empresarial,
                donde se indican las condiciones pactadas.</Typography>
            </Grid>
          </Grid> 
        </Grid> 

        <Button
          className={classes.button}
          value='/planes'
          size="large" 
          onClick={(e) => goto(e)}>Iniciar</Button>

      </Container>
    </Grid>
  );
} 
